import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  namespaced: true,
  state: {
    users: [],
  },
  getters: {
    users(state) {
      return state.users
    },
  },
  mutations: {
    SET_USERS(state, payload) {
      state.users = payload
    },
  },
  actions: {
    async uploadFile(context, payload) {
      const formData = new FormData()
      formData.append('filedata', payload.avatar)

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        // onUploadProgress(e) {
        //   if (e.lengthComputable) {
        //     // eslint-disable-next-line prefer-template
        //     console.log(Math.round((e.loaded / e.total) * 100) + '%')
        //   }
        // },
      }

      return axios.post(
        'gallery/upload',
        formData,
        config,
      )
    },
    async fetchUsers(context, payload) {
      return axios.get('user', payload)
    },
    async fetchUser(context, payload) {
      return axios.get(`user/${payload.id}`)
    },
    async addUser(context, payload) {
      const res = await axios.post('user', payload)

      const { data } = res

      if (data) {
        // eslint-disable-next-line no-underscore-dangle
        this._vm.$toast({
          component: ToastificationContent,
          position: 'top-center',
          props: {
            title: 'Оповещение',
            icon: 'InfoIcon',
            variant: 'success',
            text: `Пользователь "${payload.fio}" добавлен`,
          },
        })
      }

      return res
    },
    async updateProfile(context, payload) {
      const res = await axios.put('user/profile', payload)
      const { data } = res

      if (data) {
        // eslint-disable-next-line no-underscore-dangle
        this._vm.$toast({
          component: ToastificationContent,
          position: 'top-center',
          props: {
            title: 'Оповещение',
            icon: 'InfoIcon',
            variant: 'success',
            text: 'Профиль обновлен',
          },
        })
      }

      return res
    },
    async updateUser(context, payload) {
      const res = await axios.put(`user/${payload.id}`, payload)
      const { data } = res

      if (data) {
        // eslint-disable-next-line no-underscore-dangle
        this._vm.$toast({
          component: ToastificationContent,
          position: 'top-center',
          props: {
            title: 'Оповещение',
            icon: 'InfoIcon',
            variant: 'success',
            text: `Пользователь "${payload.fio}" обновлен`,
          },
        })
      }

      return res
    },
    async blockUser(context, payload) {
      // eslint-disable-next-line no-underscore-dangle
      return this._vm.$swal({
        text: `Заблокировать пользователя "${payload.fio}"?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        preConfirm() {
          return axios.put(`user/${payload.id}`, {
            status: 0,
          })
        },
      }).then(result => {
        if (result.isConfirmed) {
          const { data } = result.value
          if (data) {
            // eslint-disable-next-line no-underscore-dangle
            this._vm.$swal({
              icon: 'success',
              text: `Пользователь "${payload.fio}" заблокирован`,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        }

        return result
      })
    },
    async unBlockUser(context, payload) {
      // eslint-disable-next-line no-underscore-dangle
      return this._vm.$swal({
        text: `Разаблокировать пользователя "${payload.fio}"?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        preConfirm() {
          return axios.put(`user/${payload.id}`, {
            status: 10,
          })
        },
      }).then(result => {
        if (result.isConfirmed) {
          const { data } = result.value
          if (data) {
            // eslint-disable-next-line no-underscore-dangle
            this._vm.$swal({
              icon: 'success',
              text: `Пользователь "${payload.fio}" разаблокирован`,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        }

        return result
      })
    },
    async unconfirmUser(context, payload) {
      // eslint-disable-next-line no-underscore-dangle
      return this._vm.$swal({
        text: `Снять подтверждение с пользователя "${payload.fio}"?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        preConfirm() {
          return axios.put(`user/${payload.id}`, {
            status: 9,
          })
        },
      }).then(result => {
        if (result.isConfirmed) {
          const { data } = result.value
          if (data) {
            // eslint-disable-next-line no-underscore-dangle
            this._vm.$swal({
              icon: 'success',
              text: `Подтверждение с пользователя "${payload.fio}" снято`,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        }

        return result
      })
    },
    async confirmUser(context, payload) {
      // eslint-disable-next-line no-underscore-dangle
      return this._vm.$swal({
        text: `Подтвердить пользователя "${payload.fio}"?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        preConfirm() {
          return axios.put(`user/${payload.id}`, {
            status: 10,
          })
        },
      }).then(result => {
        if (result.isConfirmed) {
          const { data } = result.value
          if (data) {
            // eslint-disable-next-line no-underscore-dangle
            this._vm.$swal({
              icon: 'success',
              text: `Пользователь "${payload.fio}" подтвержден`,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        }

        return result
      })
    },
    async deleteUser(context, payload) {
      // eslint-disable-next-line no-underscore-dangle
      return this._vm.$swal({
        text: `Удалить пользователя "${payload.fio}"?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        preConfirm() {
          return axios.delete(`user/${payload.id}`)
        },
      }).then(result => {
        if (result.isConfirmed) {
          const { data } = result.value
          if (data) {
            // eslint-disable-next-line no-underscore-dangle
            this._vm.$swal({
              icon: 'success',
              text: `Пользователь "${payload.fio}" удален`,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        }

        return result
      })
    },
    async fetchTariffs(context, payload) {
      return axios.get('settings', payload)
    },
  },
}
