<template>
  <b-card>
    <!-- form -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col sm="6">
            <b-form-group
              label="Логин"
              label-for="login"
            >
              <b-form-input
                v-model="userData.login"
                name="login"
              />
            </b-form-group>
          </b-col>
          <!-- new password -->
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="password"
            >
              <b-form-group
                label-for="account-new-password"
                label="Новый пароль"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-new-password"
                    v-model="userData.password"
                    :state="getValidationState(validationContext)"
                    :type="passwordFieldTypeNew"
                    name="password"
                    placeholder="******"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="retype-password"
              :rules="'equal:' + userData.password"
            >
              <b-form-group
                label-for="account-retype-new-password"
                label="Повторить пароль"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-retype-new-password"
                    v-model="userData['retype-password']"
                    :state="getValidationState(validationContext)"
                    :type="passwordFieldTypeRetype"
                    name="retype-password"
                    placeholder="******"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              type="submit"
            >
              {{ $t('Save Changes') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mt-1"
            >
              {{ $t('Reset') }}
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput,
  BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  required,
  equal,
} from '@validations'
// import useJwt from '@/auth/jwt/useJwt'
import store from '@/store'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BFormInvalidFeedback,

    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    required,
    equal,
    passwordFieldTypeNew: 'password',
    passwordFieldTypeRetype: 'password',
  }),
  computed: {
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
  },
  setup(props) {
    const onSubmit = () => {
      store.dispatch('app-users/updateProfile', {
        login: props.userData.login || undefined,
        password: props.userData.password,
      })
    }

    const resetuserData = () => {
      // eslint-disable-next-line no-param-reassign
      props.userData.password = ''
      // eslint-disable-next-line no-param-reassign
      props.userData['retype-password'] = ''
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>
