<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Основные</span>
      </template>
      <profile-setting-general
        v-if="userData"
        :user-data="userData"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- change account tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Аккаунт</span>
      </template>

      <profile-setting-account
        v-if="userData"
        :user-data="userData"
      />
    </b-tab>
    <!--/ change account tab -->
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import usersStoreModule from '../users/usersStoreModule'
import ProfileSettingGeneral from './ProfileSettingGeneral.vue'
import ProfileSettingAccount from './ProfileSettingAccount.vue'

export default {
  components: {
    BTabs,
    BTab,
    ProfileSettingGeneral,
    ProfileSettingAccount,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-users'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, usersStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const userData = ref(JSON.parse(localStorage.getItem('userData')))

    if (userData.value.fio) {
      const parts = userData.value.fio.split(' ')
      userData.value.first_name = parts[0] || ''
      userData.value.last_name = parts[1] || ''
      userData.value.patronymic = parts[2] || ''
    } else {
      userData.value.first_name = ''
      userData.value.last_name = ''
      userData.value.patronymic = ''
    }

    return {
      userData,
    }
  },
}
</script>
