<template>
  <b-card>

    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img
            ref="previewEl"
            rounded
            :src="userData.avatar && `${server}/${userData.avatar}`"
            height="80"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="refInputEl.click()"
        >
          <span v-if="userData.avatar">{{ $t('Update') }}</span>
          <span v-else>{{ $t('Upload') }}</span>
        </b-button>
        <input
          ref="refInputEl"
          type="file"
          class="d-none"
          @input="inputImageRenderer"
        >
        <!--/ upload button -->
        <b-card-text>Допускаются JPG, GIF или PNG. Максимальный размер 800 КБ</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- form -->
      <b-form
        class="mt-2"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col sm="6">
            <b-form-group
              label="Имя"
              label-for="first_name"
            >
              <b-form-input
                v-model="userData.first_name"
                name="first_name"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Фамилия"
              label-for="last_name"
            >
              <b-form-input
                v-model="userData.last_name"
                name="last_name"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Отчество"
              label-for="patronymic"
            >
              <b-form-input
                v-model="userData.patronymic"
                name="patronymic"
              />

            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Телефон"
              label-for="phone"
            >
              <b-form-input
                v-model="userData.phone"
                name="phone"
                v-mask="'+7(###) ###-####'"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="E-mail"
              label-for="email"
            >
              <b-form-input
                v-model="userData.email"
                name="email"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              type="submit"
            >
              {{ $t('Save Changes') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
            >
              {{ $t('Reset') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import {
  // ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import store from '@/store'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,

    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const server = process.env.VUE_APP_SERVER

    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, () => {
      store.dispatch('app/uploadFile', {
        file: refInputEl.value.files[0],
      }).then(res => {
        if (res.filename) {
          // eslint-disable-next-line no-param-reassign
          props.userData.avatar = res.filename
        }
      })
    })

    const resetuserData = () => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      if (userData.fio) {
        const parts = userData.fio.split(' ')
        // eslint-disable-next-line no-param-reassign
        props.userData.first_name = parts[0] || ''
        // eslint-disable-next-line no-param-reassign
        props.userData.last_name = parts[1] || ''
        // eslint-disable-next-line no-param-reassign
        props.userData.patronymic = parts[2] || ''
      } else {
        // eslint-disable-next-line no-param-reassign
        props.userData.first_name = ''
        // eslint-disable-next-line no-param-reassign
        props.userData.last_name = ''
        // eslint-disable-next-line no-param-reassign
        props.userData.patronymic = ''
      }

      // eslint-disable-next-line no-param-reassign
      props.userData.email = userData.email || ''
      // eslint-disable-next-line no-param-reassign
      props.userData.phone = userData.phone || ''
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    const onSubmit = () => {
      const fio = [props.userData.first_name, props.userData.last_name, props.userData.patronymic].join(' ')
      store.dispatch('app-users/updateProfile', {
        fio,
        phone: props.userData.phone,
        email: props.userData.email,
        avatar: props.userData.avatar,
      }).then(() => {
        localStorage.setItem('userData', JSON.stringify({
          ...props.userData,
          fio,
        }))
        store.commit('app/SET_USER', {
          ...props.userData,
          fio,
        })
      })
    }

    return {
      server,
      refFormObserver,
      getValidationState,
      onSubmit,
      resetForm,
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
